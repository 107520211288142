import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserPolls from "../components/UserDataView";

const UserDashboard = () => (
  <Container className="mt-5">
    <Row className="justify-content-center">
      <Col xs={12} md={6}>
        <h2 className="text-center mb-4">Currently Live Polls</h2>
        <UserPolls />
      </Col>
    </Row>
  </Container>
);

export default UserDashboard;
