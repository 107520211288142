import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { signup } from "../actions/actions";
import statesData from "./statesData";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
  const [message, setMessage] = useState(null);

  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [lga, setLga] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    signup(name, email, password, phoneNumber, nin, twoFactorCode)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            window.location.href = responseData?.redirect || "/login";
            setMessage({
              type: "success",
              text: "Please verify your account to complete signup",
            });
          });
        } else {
          response.json().then((responseData) => {
            if (responseData?.message.includes("Two factor")) {
              setMessage({
                type: "info",
                text: responseData?.message,
              });
              setShowTwoFactorInput(true);
            } else if (
              responseData?.message.includes("User created successfully")
            ) {
              setMessage({
                type: "success",
                text: responseData?.message || "Signup successful",
              });
              navigate("/login");
            } else {
              setMessage({
                type: "info",
                text: responseData?.message || "Signup failed",
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during signup",
        });
      });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const states = statesData;

  const handleStateChange = (selectedState) => {
    setState(selectedState);
    const selectedStateInfo = states.find((s) => s.name === selectedState);
    if (selectedStateInfo) {
      setStateId(selectedStateInfo.id);
      setLga("");
    }
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your phone number"
            name="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>NIN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your NIN"
            name="nin"
            value={nin}
            onChange={(e) => setNin(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        {showTwoFactorInput && (
          <Form.Group controlId="formTwoFactorCode">
            <Form.Label>Two Factor Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter two factor code sent"
              name="twoFactorCode"
              value={twoFactorCode}
              onChange={(e) => setTwoFactorCode(e.target.value)}
              style={{ marginBottom: "20px" }}
              required
            />
          </Form.Group>
        )}

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
          <div className="form-check ml-2 mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={handleTogglePassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show Password
            </label>
          </div>
        </Form.Group>

        <Form.Group controlId="formState">
          <Form.Label>State</Form.Label>
          <Form.Control
            as="select"
            value={state}
            onChange={(e) => handleStateChange(e.target.value)}
            required
            style={{ marginBottom: "15px" }}
          >
            <option value="">Select State</option>
            {states.map((s) => (
              <option key={s.name}>{s.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        {state && (
          <Form.Group controlId="formStateId">
            <Form.Label>State ID</Form.Label>
            <Form.Control
              type="text"
              value={stateId}
              style={{ marginBottom: "15px" }}
            />
          </Form.Group>
        )}

        {state && (
          <Form.Group controlId="formLga">
            <Form.Label>LGA</Form.Label>
            <Form.Control
              as="select"
              value={lga}
              onChange={(e) => setLga(e.target.value)}
              style={{ marginBottom: "15px" }}
              required
            >
              <option value="">Select LGA</option>
              {states
                .find((s) => s.name === state)
                ?.lgas.map((lga) => (
                  <option key={lga}>{lga}</option>
                ))}
            </Form.Control>
          </Form.Group>
        )}

        <Button variant="primary" type="submit" className="w-100 my-3">
          Sign Up
        </Button>
      </Form>
    </>
  );
};

export default SignupForm;
