import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Outlet, Link, useLocation } from "react-router-dom";
import { logout } from "../actions/actions";
import logoImage from "./images/logo.png";
import "./styles.css";

const WebsiteNavbar = () => {
  const location = useLocation();
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

  document.title = "NaijaE-Democracy - " + "polling"; //process.env.REACT_APP_MODE;

  React.useEffect(() => {
    setIsUserLoggedIn(location.pathname.includes("/dashboard"));
  }, [location]);

  const handleLogout = () => {
    logout();
    localStorage.removeItem("jwtToken");
    setIsUserLoggedIn(false);
  };

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        sticky="top"
        className="navbar-container"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <div style={{ display: "flex", flex: "row" }}>
              <img
                src={logoImage}
                alt="Logo"
                style={{
                  width: "35px",
                  height: "35px",
                  marginRight: "10px",
                  marginBottom: "5px",
                }}
              />
              <h3>NaijaE-Democracy - {" " + process.env.REACT_APP_MODE}</h3>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {isUserLoggedIn ? (
                <Nav.Link as={Link} to="/login" onClick={handleLogout}>
                  LOGOUT
                </Nav.Link>
              ) : (
                <>
                  <Nav.Link as={Link} to="/login">
                    LOGIN
                  </Nav.Link>
                  <Nav.Link as={Link} to="/signup">
                    SIGNUP
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default WebsiteNavbar;
