import React, { useEffect, useState } from "react";
import { Tab, Nav, Button, Table } from "react-bootstrap";
import CreateAndEditPollForm from "./CreateAndEditPollForm";
import EditPollModal from "./EditPollModal";
import { getAllPolls, getJwtToken } from "../actions/actions";
import { useNavigate } from "react-router-dom";

const AdminDataView = () => {
  const [polls, setPolls] = useState([]);
  const [activeKey, setActiveKey] = useState("view");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = getJwtToken();
    if (!jwtToken) navigate("/login");

    loadPollsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPollsData = () => {
    getAllPolls()
      .then((response) => {
        if (response.status === 403) {
          navigate("/login");
          throw new Error("Access denied. Please login.");
        }
        return response.json();
      })
      .then((data) => {
        setPolls(data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching active polls; " + error.message);
        console.error("Error fetching active polls:", error);
      });
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleEditPoll = (poll) => {
    setSelectedPoll(poll);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    loadPollsData();
    setShowEditModal(false);
  };

  const sortByStatus = () => {
    const sortedPolls = [...polls]?.sort((a, b) => {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });
    setPolls(sortedPolls);
  };

  const renderPollsTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Multiple Choices</th>
            <th>Options</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {polls?.map((poll) => (
            <tr key={poll.id}>
              <td style={{ overflow: "auto" }}>{poll.title}</td>
              <td style={{ overflow: "auto" }}>{poll.description}</td>
              <td style={{ overflow: "auto" }}>{poll.start_time}</td>
              <td style={{ overflow: "auto" }}>{poll.end_time}</td>
              <td style={{ overflow: "auto" }}>{poll.status}</td>
              <td style={{ overflow: "auto" }}>
                {poll.allow_multiple_votes ? "Yes" : "No"}
              </td>
              <td style={{ overflow: "auto" }}>
                <ul>
                  {poll.options.map((option, index) => (
                    <li key={index}>
                      <strong>{option.title}</strong>:{" "}
                      {option.description || "-"}, Votes:{" "}
                      <strong> {option.voters || 0} </strong>
                    </li>
                  ))}
                </ul>
              </td>
              <td style={{ overflow: "auto" }}>
                {poll.status === "draft" &&
                  poll.options.every((option) => option.voters < 1) && (
                    <Button variant="info" onClick={() => handleEditPoll(poll)}>
                      Edit
                    </Button>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="container mt-5">
      <Tab.Container
        id="admin-data-tabs"
        activeKey={activeKey}
        onSelect={handleTabChange}
      >
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="view">View & Edit Polls</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="create">Create Poll</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="view" className="w-full">
            <h4 className="my-4">View & Edit Polls</h4>
            {loading && <p>Loading...</p>}
            {error && <div className="alert alert-danger">{error}</div>}
            {renderPollsTable()}

            <div className="mb-3">
              <Button variant="primary cursor-pointer" onClick={sortByStatus}>
                Filter by Status
              </Button>
              <Button
                variant="success cursor-pointer"
                style={{ marginLeft: "10px" }}
                onClick={() => loadPollsData()}
              >
                Refresh Data
              </Button>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="create">
            <CreateAndEditPollForm />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {showEditModal && (
        <EditPollModal
          show={{ setShowEditModal }}
          handleClose={handleCloseEditModal}
          selectedPoll={selectedPoll}
        />
      )}
    </div>
  );
};

export default AdminDataView;
