import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ResetPasswordForm from "../components/ResetPasswordForm";

const ResetPasswordPage = () => (
  <Container className="mt-5">
    <Row className="justify-content-center">
      <Col xs={12} md={6}>
        <h2 className="text-center mb-4">Reset Password</h2>
        <ResetPasswordForm />
      </Col>
    </Row>
  </Container>
);

export default ResetPasswordPage;
