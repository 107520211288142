import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AdminDataView from "../components/AdminDataView";

const AdminDashboard = () => (
  <Container className="mt-5">
    <Row className="justify-content-center">
      <Col xs={12} md={12}>
        <h2 className="text-center mb-4">All Polls</h2>
        <AdminDataView />
      </Col>
    </Row>
  </Container>
);

export default AdminDashboard;
