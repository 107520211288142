import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { resetPassword } from "../actions/actions";

const ResetPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = window.location.pathname.split("/").pop();

    resetPassword(password, confirmPassword, token)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            setMessage({
              type: "success",
              text: responseData?.message || "Password Reset successful",
            });
            navigate("/login");
          });
        } else {
          response.json().then((responseData) => {
            setMessage({
              type: "danger",
              text: responseData?.message || "Password Reset failed",
            });
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during password reset",
        });
      });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter new password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Confirm new password"
            name="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
          <div className="form-check ml-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={handleTogglePassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show Passwords
            </label>
          </div>
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100 mt-3">
          Change Password
        </Button>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
