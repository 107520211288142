const SITE_BASE_URL =
  process.env.REACT_APP_BACKEND_BASE_URL || window.location.origin;

export const getJwtToken = () => localStorage.getItem("jwtToken", "");

export const signup = (
  name,
  email,
  password,
  phoneNumber,
  nin,
  twoFactorCode,
) =>
  fetch(`${SITE_BASE_URL}/api/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      phone_number: phoneNumber,
      nin: nin,
      two_factor_code: twoFactorCode,
    }),
  });

export const login = (phoneNumber, nin, password, twoFactorCode) =>
  fetch(`${SITE_BASE_URL}/api/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phone_number: phoneNumber,
      nin: nin,
      password: password,
      two_factor_code: twoFactorCode,
    }),
  });

export const getAdminPollsData = () => {
  return fetch(`${SITE_BASE_URL}/api/admin/polls`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
    },
  });
};

export const getActivePolls = () => {
  return fetch(`${SITE_BASE_URL}/api/polls/active`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
    },
  });
};

export const submitVote = (pollId, optionTitle) => {
  return fetch(`${SITE_BASE_URL}/api/poll/${pollId}/vote`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`,
    },
    body: JSON.stringify({
      option_title: optionTitle,
    }),
  });
};

export const createPoll = (
  title,
  description,
  startDate,
  endDate,
  status,
  options,
  AllowMultipleVotes,
) => {
  return fetch(`${SITE_BASE_URL}/api/poll/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`,
    },
    body: JSON.stringify({
      title: title,
      description: description,
      start_date: startDate,
      end_date: endDate,
      status: status,
      options: options,
      allow_multiple_votes: AllowMultipleVotes,
    }),
  });
};

export const getAllPolls = () => {
  return fetch(`${SITE_BASE_URL}/api/polls`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
    },
  });
};

export const editPoll = (
  pollId,
  title,
  description,
  startDate,
  endDate,
  status,
  options,
  AllowMultipleVotes,
) => {
  return fetch(`${SITE_BASE_URL}/api/poll/${pollId}/edit`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`,
    },
    body: JSON.stringify({
      title: title,
      description: description,
      start_date: startDate,
      end_date: endDate,
      status: status,
      options: options,
      allow_multiple_votes: AllowMultipleVotes,
    }),
  });
};

export const logout = () =>
  fetch(`${SITE_BASE_URL}/api/logout`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
    },
  });

export const forgetPassword = (phoneNumber, nin) =>
  fetch(`${SITE_BASE_URL}/api/forget-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phone_number: phoneNumber,
      nin: nin,
    }),
  });

export const resetPassword = (password, confirmPassword, token) =>
  fetch(`${SITE_BASE_URL}/api/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      new_password: password,
      confirm_new_password: confirmPassword,
      token: token,
    }),
  });
