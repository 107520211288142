import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignupForm from "../components/SignupForm";

const SignupPage = () => (
  <Container className="mt-5">
    <Row className="justify-content-center">
      <Col xs={12} md={6}>
        <h2 className="text-center mb-4">Signup</h2>
        <SignupForm />
      </Col>
    </Row>
  </Container>
);

export default SignupPage;
