import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { forgetPassword, login, getJwtToken } from "../actions/actions";

const LoginForm = () => {
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const isRedirectedFromVeriff = window.location.search.includes("veriff");

    if (isRedirectedFromVeriff) {
      setMessage({
        type: "info",
        text: "If you completed the verification step, then it will take some time to reflect. And if you did not complete the verification, please signup again.",
      });
    }

    const jwtToken = getJwtToken();
    if (jwtToken) navigate("/user/dashboard");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    login(phoneNumber, nin, password, twoFactorCode)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            localStorage.setItem("jwtToken", responseData?.access_token);
            setMessage({
              type: "success",
              text: responseData?.message || "Login successful",
            });
            if (responseData?.role === "admin") navigate("/admin/dashboard");
            else navigate("/user/dashboard");
          });
        } else {
          response.json().then((responseData) => {
            if (responseData?.message.includes("Two factor")) {
              setMessage({
                type: "info",
                text: responseData?.message,
              });
              setShowTwoFactorInput(true);
            } else {
              setMessage({
                type: "danger",
                text: responseData?.message || "Login failed",
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during login",
        });
      });
  };

  const handleForgetPassword = (e) => {
    e.preventDefault();

    forgetPassword(phoneNumber, nin)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            setMessage({
              type: "success",
              text:
                responseData?.message ||
                "Password reset email sent to your registered email.",
            });
          });
        } else {
          response.json().then((responseData) => {
            setMessage({
              type: "danger",
              text:
                responseData?.message || "Unable to send password reset email",
            });
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during password reset",
        });
      });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPassword">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your phone number"
            name="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>NIN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your NIN"
            name="nin"
            value={nin}
            onChange={(e) => setNin(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        {showTwoFactorInput && (
          <Form.Group controlId="formTwoFactorCode">
            <Form.Label>Two Factor Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter two factor code sent"
              name="twoFactorCode"
              value={twoFactorCode}
              onChange={(e) => setTwoFactorCode(e.target.value)}
              style={{ marginBottom: "20px" }}
              required
            />
          </Form.Group>
        )}

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
          <div className="form-check ml-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={handleTogglePassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show Password
            </label>
          </div>
        </Form.Group>

        <p
          className="text-end text-primary"
          style={{ cursor: "pointer" }}
          onClick={handleForgetPassword}
        >
          Forgot Password
        </p>

        <Button variant="primary" type="submit" className="w-100 mt-1">
          Login
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
