import React, { useEffect, useState } from "react";
import { getActivePolls, submitVote, getJwtToken } from "../actions/actions";
import { useNavigate } from "react-router-dom";

const UserDataView = () => {
  const [polls, setPolls] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = getJwtToken();
    if (!jwtToken) navigate("/login");

    getActivePolls()
      .then((response) => response.json())
      .then((data) => {
        setPolls(data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching active polls; " + error.message);
        console.error("Error fetching active polls:", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVote = (pollId) => {
    submitVote(pollId, selectedOption[pollId]).then((response) => {
      if (response.ok) {
        setSuccessMessage("Vote submitted successfully");
        setError("");
      } else {
        response
          .json()
          .then((data) => {
            if (data && data.message) {
              setError("Error: " + data.message);
              setSuccessMessage("");
            } else {
              setError("Error submitting vote: " + response.statusText);
              setSuccessMessage("");
            }
          })
          .catch((error) => {
            setError("Error parsing response: " + error.message);
            setSuccessMessage("");
          });
      }
    });
  };

  const handleOptionChange = (pollId, optionTitle, allowMultipleVotes) => {
    if (allowMultipleVotes) {
      const updatedOptions = { ...selectedOption };
      if (updatedOptions[pollId]?.includes(optionTitle)) {
        updatedOptions[pollId] = updatedOptions[pollId].filter(
          (selectedOptionTitle) => selectedOptionTitle !== optionTitle,
        );
      } else {
        updatedOptions[pollId] = [
          ...(updatedOptions[pollId] || []),
          optionTitle,
        ];
      }
      setSelectedOption(updatedOptions);
    } else {
      setSelectedOption({ ...selectedOption, [pollId]: optionTitle });
    }
  };

  return (
    <div className="container mt-5 overflow-scroll">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && (
            <div className="alert alert-success">{successMessage}</div>
          )}
          {polls?.map((poll) => (
            <div
              key={poll.poll_id}
              className="my-4 border border-2 overflow-auto p-3"
            >
              <h3>{poll.title}</h3>
              <p>{poll.description}</p>

              <ul className="list-group">
                {poll.options?.map((option) => (
                  <li
                    className=" d-flex justify-content-between align-items-center"
                    key={option.id}
                  >
                    <div>
                      {poll.allow_multiple_votes ? (
                        <input
                          type="checkbox"
                          id={`option-${option.id}`}
                          name={`option-${poll.id}`}
                          value={option.id}
                          checked={
                            selectedOption[poll.id]?.includes(option.title) ||
                            false
                          }
                          onChange={() =>
                            handleOptionChange(
                              poll.id,
                              option.title,
                              poll.allow_multiple_votes,
                            )
                          }
                        />
                      ) : (
                        <input
                          type="radio"
                          id={`option-${option.id}`}
                          name={`option-${poll.id}`}
                          value={option.id}
                          checked={selectedOption[poll.id] === option.title}
                          onChange={() =>
                            handleOptionChange(
                              poll.id,
                              option.title,
                              poll.allow_multiple_votes,
                            )
                          }
                        />
                      )}
                      <label htmlFor={`option-${option.id}`} className="ms-2">
                        {option.title}{" "}
                        <span className="text-secondary">
                          {option?.description && `(${option.description})`}
                        </span>
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
              <button
                className="btn btn-primary mt-3 me-2"
                style={{ float: "right" }}
                onClick={() => handleVote(poll.id)}
                disabled={!selectedOption[poll.id]}
              >
                Vote
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserDataView;
